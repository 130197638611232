.navbar {
  color: #fff;
}

.nav-top-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  background-color: var(--primary_color);
}

.nav-top-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  max-height: 100px;
  padding: 6px 20px;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  color: var(--font_color_light);
  text-decoration: none;
}

.logo-wrapper>h1 {
  font-size: 3rem;
  margin-left: 15px;
}

.nav-logo {
  max-height: 80px;
}

.logo-text {
  font-family: 'Bebas Neue', sans-serif;
}

.nav-buttons button,
.mobile-nav-buttons button {
  background: white;
  color: var(--primary_color);
  border: 2px solid var(--primary_color);
  padding: 6px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-family: inherit;
  font-size: 1em;
}

.pay-btn {
  margin-right: 10px;
}

.sidebar-left-container {
  position: absolute;
  left: 0;
  height: calc(100% - 100px);
  width: 300px;
  background-color: var(--primary_color);
}

.side-curve {
  position: absolute;
  left: 300px;
}

.sidebar-curve {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: var(--primary_color);
}

.sidebar-curve-white {
  position: absolute;
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 100px 0 0 0;
}

.sidebar-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-item {
  margin-top: 25%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
}

.nav-link {
  color: white;
  font-size: 1rem;
  text-decoration: none;

  line-height: 100%;
  height: 2rem;
}

.tenant-dashboard {
  cursor: pointer;
  font-size: 25px;
}

.nav-link>p {
  border-bottom: 1px solid transparent;
}

.current>p {
  border-bottom: 1px solid var(--shadow);
}

.nav-icon {
  /* height: 100%; */
  width: 20px;
  margin-right: 15px;
}

.footer-info {
  padding: 0 15px;
}

.footer-info>div>h5 {
  margin-bottom: 0;
}

.footer-info>h4 {
  margin: 0;
}

.telephone-number {
  color: white;
}

.nav-block {
  display: flex;
  align-items: center;
}

.footer-logo {
  height: 75px;
  width: 75px;
  margin: 20px 0 20px 0;
  border-radius: 20px;
  background-color: white;
  padding: 5px;
  border: solid 2px black;
}

.hamburger-menu-icon,
.hamburger-menu-items,
.hamburger-menu,
.mobile-footer,
.ham-open,
.ham-closed {
  display: none;
}

/* Responsive adjustments */
/* height related adjustments (tested on macbook pro 13") */
/* @media only screen and (max-height: 1070px) {
  .menu-item {
    margin: 0;
  }

  .nav-link {
    line-height: 100%;
    height: 2rem;
  }
} */

/* width related adjustments */
@media only screen and (max-height: 900px) {

  .sidebar-curve-white,
  .sidebar-curve,
  .side-curve,
  .sidebar-left-container {
    display: none;
  }

  .logo-wrapper>h1 {
    font-size: 2rem;
    margin-left: 10px;
  }

  .nav-logo {
    height: 50px;
  }

  .hamburger-menu-icon,
  .mobile-nav-buttons {
    display: block;
  }

  .hamburger-menu-items {
    display: none;
  }

  .ham-open {
    display: inline;
    position: absolute;
    background-color: var(--primary_color);
    top: 100px;
    padding: 15px;
    right: 0;
    z-index: 9;
  }

  .mobile-footer-info {
    margin-top: 50px;
    background-color: var(--primary_color);
    padding: 0 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    color: white;
  }

  .mobile-footer-info>div>h3 {
    font-size: 16px;
  }

  .link-label {
    font-size: 22px;
    line-height: 27px;
  }

  .nav-block,
  .nav-link {
    margin-bottom: 20px;
  }

  .nav-buttons {
    display: none;
  }

  .forgot-password-form {
    margin: 0 15px;
    padding: 15px;
  }
}