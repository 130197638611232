.login-form {
    width: 50%;
    max-width: 500px;
    padding: 50px;
}

.login-form>img {
    width: 50%;
    margin: auto;
    margin-bottom: 40px;
}

.login-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.login-block>label {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #5E5E5E;
    text-align: center;
    margin-bottom: 15px;
}

.login-block>input {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: center;
    height: 40px;
    background: #F5F5F5;
    border: 1px solid #3B517A;
    box-shadow: inset 2px 2px 10px rgba(59, 81, 122, 0.5);
    border-radius: 5px;
}

.forgotten {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #5E5E5E;
    text-align: center;
    margin-bottom: 30px;
}

.login-header,
.dashboard-header {
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #5E5E5E;
    margin: 150px 0 60px 0;
    text-transform: uppercase;
}

.dashboard-header {
    margin-top: 50px;
}

.mobile-only {
    display: none;
}

/* Responsive adjustments */
/* height related adjustments (tested on macbook pro 13") */
@media only screen and (max-height: 1070px) {

    .login-header,
    .dashboard-header {
        margin: 5vh 0 0 0;
    }
}

@media only screen and (max-height: 900px) {
    .login-form {
        width: 90%;
        padding: 15px;
    }

    .login-header,
    .dashboard-header {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 20px;
    }

    .login-block>label {
        font-size: 20px;
    }

    .mobile-only {
        display: block;
    }
}