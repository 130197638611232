.sidebar-right-container {
    position: absolute;
    top: 100px;
    right: 0;
    height: calc(100% - 100px);
    width: 300px;
    background-color: var(--secondary_color);
    padding: 0 15px 0 15px;
    overflow: hidden;
}

.top-header {
    margin: 100px 0 50px 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.right-sidebar-header {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px;
}

.weather-widget {
    margin-bottom: 50px;
}

.admin-button {
    position: absolute;
    bottom: 15px;
}

.announcement-date {
    font-style: italic;
    font-size: small;
    margin-top: -11px;
}

.announcement-wrapper {
    height: 40%;
    padding-bottom: 50px;
}

.announcement-block {
    border-bottom: 1px solid black;
}

.announcement-block:last-child {
    border: none;
}

/* .announcement-wrapper>.announcement-block>.material-symbols-outlined {
    display: none;
} */

@media only screen and (max-height: 900px) {
    .sidebar-right-container {
        display: none;
    }

    .top-header {
        margin-top: 20px;
    }

    .top-header,
    .right-sidebar-header {
        text-align: center;
    }

    .announcement-wrapper {
        padding: 0 15px;
    }

    .admin-button {
        position: relative;
        color: black;
    }

    .weather-widget {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}