@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');


:root {
  --primary_color: #011e41;
  --secondary_color: rgba(242, 206, 27, .2);
  --font_color_light: #ffffff;
  --font_color_dark: #565F5F;
  --shadow: #F2CE1B;
  --border_test: 1px solid red;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.hide {
  display: none;
}

.notOptimized {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 15px 30px 15px 30px;
  color: white;
  background-color: red;
  border: 2px solid black;
  max-width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form {
  display: flex;
  flex-direction: column;
  margin: auto;
  border: 1px solid #3B517A;
  border-radius: 15px;
}

form>button,
.announceChangeCancel {
  background: white;
  color: var(--primary_color);
  border: 2px solid var(--primary_color);
  padding: 6px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-family: inherit;
  font-size: 1em;
  text-align: center;
}

iframe {
  border-width: 0;
}

.pages {
  padding: 0 350px;
  height: calc(100vh - 115px);
}

.page {
  height: calc(100vh - 115px);
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.page-body {
  /* padding-top: 150px; */
  height: calc(100% - 250px);
  /* width: 100%; */
}

.page-body>iframe {
  padding-top: 25px;
  width: 100%;
  height: 100%;
}

.app {
  height: 100vh;
  overflow: hidden;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 50px;
  z-index: 5;
}

.page-header>img {
  height: 30px;
  margin: 50px 0 50px 0;
}

.page-header>h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #5E5E5E;
  text-transform: uppercase;
}

.construction-logo {
  width: 75%;
}

.org-wrapper {
  border: var(--primary_color) 1px solid;
  margin-bottom: 50px;
}

.org-details,
.user-details {
  padding: 15px;
  position: relative;
  width: 100%-30px;
  border-bottom: 1px solid black;
}

.org-details:nth-child(even),
.user-details:nth-child(even) {
  background-color: var(--secondary_color);
}

.org-details:last-child,
.user-details:last-child {
  border: none;
}

.address-block {
  margin: auto 0 auto 0;
}

.org-details h4,
.user-details h4 {
  margin: auto 0 auto 0;
  font-size: 1rem;
  color: black;
}

.org-details p,
.user-details p {
  margin: auto 0 auto 0;
  font-size: 0.9em;
  color: #555;
}

.org-details span,
.user-details span {
  width: 25px;
  margin: auto;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
  background: white;
  padding: 6px;
  border-radius: 50%;
  color: var(--primary_color);
}

/* small forms */
.multi-label-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.single-label-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.single-label-group>label {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: center;
  margin-bottom: 15px
}

.single-label-group>input {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: center;
  height: 1.5rem;
  background: #F5F5F5;
  border: 1px solid #3B517A;
  box-shadow: inset 1px 1px 5px rgba(59, 81, 122, 0.5);
  border-radius: 5px;
}

.single-label-group>textarea {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.2rem;
  background: #F5F5F5;
  border: 1px solid #3B517A;
  box-shadow: inset 1px 1px 5px rgba(59, 81, 122, 0.5);
  border-radius: 5px;
}

.half-half {
  width: 48%;
}

.signup,
.announcement-form,
.registration-form {
  padding: 25px 50px 25px 50px;
  margin-bottom: 50px;
}

.registration-form>button {
  background: white;
  color: var(--primary_color);
  border: 2px solid var(--primary_color);
  padding: 6px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-family: inherit;
  font-size: 1em;
}

.admin-grid,
.user-grid {
  display: grid;
  gap: 100px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 100px 50px 0 50px;
}

.user-grid {
  height: 50%;
  grid-template-columns: 1fr 1fr;
}

.user-icon {
  margin: 10px 0 10px 0;
  width: 40px;
}

.admin-grid {
  grid-template-columns: 1fr 1fr;
}

.admin-dashboard-button,
.user-dashboard-button {
  border: 1px solid #3B517A;
  border-radius: 15px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #F5F5F5;
  text-decoration: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: black;
  height: 250px;
  min-width: 250px;
  width: 60%;
  margin: auto;
  text-align: center;
}

.admin-dashboard-button>.material-symbols-outlined {
  font-size: 40px;
  margin-bottom: 20px;
}

.admin-dashboard-button:visited {
  color: black;
}

.admin-announce-wrapper>.announcement-block {
  border: 1px solid #3B517A;
  border-radius: 15px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
  padding: 25px;
  display: grid;
  grid-template-columns: 3.5fr .25fr;
  margin-bottom: 50px;
}

.announcement-block>.material-symbols-outlined {
  margin: auto;
}

.announcement-block>form {
  border: none;
  border-radius: none;
}

.admin-announce-wrapper>.announcement-block:last-child {
  border: 1px solid #3B517A;
}

.can-scroll {
  overflow: auto !important;
}

.popupHidden {
  display: none;
}

.popupActive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background-color: #ffffff;
  color: black;
  position: fixed;
  bottom: 15%;
  padding: 50px;
  border: 1px solid black;
  z-index: 100;
}

.closePopup {
  position: absolute;
  top: 5%;
  right: 5%;
  cursor: pointer;
}

.delete {
  cursor: pointer;
}

.material-symbols-outlined {
  cursor: pointer;
}

.editForm {
  border: none;
}

.userEmail {
  display: flex;
  align-items: center;
  color: black;
}

.userInfo,
.orgInfo {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.25fr 0.25fr;
  border: none;
  border-radius: none;
}

.orgAddressGroup {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.orgAddressLine {
  display: flex;
}

.announcement-body {
  overflow-wrap: break-word;
}

.mobile-dashboard {
  display: none;
}

.editDelete {
  display: flex;
}

.mobile-spacer {
  display: none;
}

/* RESPONSIVE STYLE ADJUSTMENTS */

/* Screen Width */

/* @media only screen and (max-width: 1000px) {
  .notOptimized {
    display: block;
  }
} */

/* Screen Height */
@media only screen and (min-height: 1080px) {
  .admin-grid {
    height: 50%;
  }
}

@media only screen and (max-height: 900px) {

  /* .notOptimized {
    display: block;
  } */

  .pages {
    padding: 0 15px;
    height: auto;
    min-height: 100vh;
    width: 100%-30px;
  }

  .page,
  .page-body {
    height: auto;
    width: 100%;
  }

  .app {
    height: auto;
    overflow: auto;
  }

  .user-grid,
  .admin-grid {
    display: none;
  }

  .mobile-dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .page-body>iframe {
    min-height: 100vh;
  }

  .multi-label-group {
    flex-direction: column;
  }

  .userInfo>h4,
  .orgInfo>h4 {
    margin-right: 25px;
  }

  .userInfo,
  .orgInfo {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr .5fr;
    gap: 15px;
  }

  .half-half {
    width: 100%;
  }

  .orgAddressLine {
    display: block;
  }

  .admin-announce-wrapper>.announcement-block {
    padding: 15px;
    overflow: hidden;
  }

  .mobile-spacer {
    display: block;
  }
}